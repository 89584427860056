export const departmentsByTypeAndName = /* GraphQL */ `
  query departmentsByTypeAndName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        groupID
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        mustBeFilled
        litigationDepartment
        bankingDepartment
        visibleToTrainees
        notDecidedIndicator
        siteID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        siteID
        departmentInfo
        visibleToTrainees
        notDecidedIndicator
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      type
      name
      groupID
      numberOfSeatsAvailable
      numberOfSeatsReservedForSecondYears
      numberOfSeatsReservedForFirstYears
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      siteID
      site {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      departmentInfo
      visibleToTrainees
      notDecidedIndicator
      mustBeFilled
      litigationDepartment
      bankingDepartment
      twinDepartmentID
      createdAt
      updatedAt
    }
  }
`;

export const departmentsBySiteIDAndName = /* GraphQL */ `
  query DepartmentsBySiteIDAndName(
    $siteID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsBySiteIDAndName(
      siteID: $siteID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        siteID
        departmentInfo
        visibleToTrainees
        notDecidedIndicator
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getTrainee = /* GraphQL */ `
  query GetTrainee($id: String!) {
    getTrainee(id: $id) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        published
        createdAt
        updatedAt
      }
      ownerID
      siteID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      traineeDiary
      firstSeatSupervisor
      secondSeatSupervisor
      thirdSeatSupervisor
      fourthSeatSupervisor
      createdAt
      updatedAt
    }
  }
`;

export const getTraineeAdmin = /* GraphQL */ `
  query GetTrainee($id: String!) {
    getTrainee(id: $id) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        published
        createdAt
        updatedAt
      }
      ownerID
      siteID
      site {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      manualOverrideID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      traineeDiary
      hrComments
      firstSeatSupervisor
      secondSeatSupervisor
      thirdSeatSupervisor
      fourthSeatSupervisor
      firstSeatSupervisorHR
      secondSeatSupervisorHR
      thirdSeatSupervisorHR
      fourthSeatSupervisorHR
      createdAt
      updatedAt
    }
  }
`;

export const rotationsByTypeAndStartDate = /* GraphQL */ `
  query RotationsByTypeAndStartDate(
    $type: String!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rotationsByTypeAndStartDate(
      type: $type
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        startDate
        visible
        deadline
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const rotationsByTypeAndStartDateAdmin = /* GraphQL */ `
  query RotationsByTypeAndStartDate(
    $type: String!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rotationsByTypeAndStartDate(
      type: $type
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        visible
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getRotation = /* GraphQL */ `
  query GetRotation($id: ID!) {
    getRotation(id: $id) {
      id
      type
      startDate
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      cohortsIndex
      published
      deadline
      createdAt
      updatedAt
    }
  }
`;

export const listRotations = /* GraphQL */ `
  query ListRotations(
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        visible
        startDate
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        deadline
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const traineesByTypeAndTraineeIndex = /* GraphQL */ `
  query TraineesByTypeAndTraineeIndex(
    $type: String!
    $traineeIndex: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineesByTypeAndTraineeIndex(
      type: $type
      traineeIndex: $traineeIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        traineeIndex
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        name
        email
        cohortID
        ownerID
        siteID
        site {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        manualOverrideID
        traineeDiary
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const traineesByTypeAndName = /* GraphQL */ `
  query TraineesByTypeAndName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineesByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        name
        email
        cohortID
        ownerID
        siteID
        site {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        manualOverrideID
        traineeDiary
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listTrainees = /* GraphQL */ `
  query ListTrainees(
    $id: String
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrainees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        email
        cohortID
        siteID
        cohort {
          id
          type
          startDate
          cohortsIndex
          createdAt
          updatedAt
          __typename
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          createdAt
          updatedAt
          __typename
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        traineeDiary
        hrComments
        firstSeatSupervisorHR
        secondSeatSupervisorHR
        thirdSeatSupervisorHR
        fourthSeatSupervisorHR
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;