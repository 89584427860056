import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
    DateField,
    Show,
    SimpleShowLayout,
    useGetOne,
    useGetIdentity,
    useGetList,
    SaveButton, Toolbar
} from "react-admin";

import {RichTextInputToolbar, FormatButtons} from 'ra-input-rich-text';
import { Button, styled, Grid, useMediaQuery } from "@mui/material";
import { useState } from "react";
import {
    Typography, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions, Card
} from '@mui/material';
import { BackButton } from './BackButton';
import { ordinal_suffix_of, wordy_ordinal } from './variable_preferences';
import { DepartmentPlusSiteField } from "./Trainee";
import { Close } from '@mui/icons-material';
import {TraineeNameField} from "./Seat";
import { RichTextInput } from 'ra-input-rich-text';

const validatePreference = (values) => {
    const errors = {};
    console.warn("values", values)
    for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
        if (!values.departmentID[priority]) {
            errors['departmentID.' + priority] = "Must enter a " + wordy_ordinal(priority + 1) + " preference"
        } else {
            for (let previous_priority = 0; previous_priority < priority; previous_priority++) {
                if (values.departmentID[previous_priority] == values.departmentID[priority]) {
                    errors['departmentID.' + priority] = "Cannot enter the same department twice"
                }
            }
        }
    }


    return errors
}

export const ManyPreferenceCreateMobile = ({preferencesInput}) => {
    return (
        <>
    {preferencesInput}
    <StyledRichTextInputMobile source="comment" label="ADD COMMENTS" width={"100%"} toolbar={<RichTextInputToolbar size="large" ><></></RichTextInputToolbar>}/>
    </>
    )
}

export const ManyPreferenceCreateWeb = ({preferencesInput}) => {
    return (
    <Grid container spacing={25}>
                        <Grid item xs={2} direction="column">
                            <Grid container direction="column">
                        {preferencesInput}
                        </Grid>
                        </Grid>
                        <Grid item xs={6}>
                        <StyledRichTextInput source="comment" label="ADD COMMENTS"  toolbar={<RichTextInputToolbar size="large" ><></></RichTextInputToolbar>}/>
                        </Grid>
                    </Grid>
    )
}

export const ManyPreferenceCreate = (props) => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    );
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/');
        refresh();
    };
    const { data, isLoading } = useGetIdentity();
    const traineeData = useGetOne('trainees', { id: data?.id + "::" + data?.id })
    const sites = useGetList("sites");
    const rotationsList = useGetList('rotations');
    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentRotation = rotations?.at(-1)
    console.log(currentRotation)
    var counter = 0;
    console.log("traineeData", traineeData)
    for (let preferenceIndex in traineeData?.data?.Preferences.items) {
        if (traineeData?.data?.Preferences.items[preferenceIndex].traineeID == data?.id && traineeData?.data.Preferences.items[preferenceIndex].rotationID == currentRotation?.id) {
            counter += 1;
        }
    }
    if (counter != 0) {
        return (<Create><Card><Typography display="block">You have already submitted your preferences. Please contact your Early Careers team if you would like to change one of your preferences.</Typography></Card></Create>)
    }
    if (!isLoading && !sites.isLoading && !traineeData.isLoading) {
        // Check if there is a wildcard site
        var wildcard_site_id = ""
        var twin_site_id = ""
        for (const site in sites?.data) {
            console.log(sites?.data[site])
            if (sites?.data[site].wildcard) {
                console.log(sites?.data[site].wildcard)
                wildcard_site_id = sites?.data[site].id
            }
            if (sites?.data[site].id == traineeData.data?.siteID) {
                twin_site_id = sites?.data[site].twinSiteID
            }
        }
        var filter = { departmentsBySiteIDAndName: { siteID: traineeData.data?.siteID, visibleToTrainees: { ne: false } } };
        if (wildcard_site_id && twin_site_id) {
            filter = {
                departmentsByTypeAndName: { type: "Department", filter: { or: [{ siteID: { eq: traineeData.data?.siteID } }, { siteID: { eq: wildcard_site_id } }, { siteID: { eq: twin_site_id } }], visibleToTrainees: { ne: false } } }
            };
        } else if (wildcard_site_id) {
            filter = {
                departmentsByTypeAndName: { type: "Department", filter: { or: [{ siteID: { eq: traineeData.data?.siteID } }, { siteID: { eq: wildcard_site_id } }], visibleToTrainees: { ne: false } } }
            };
        } else if (twin_site_id) {
            filter = {
                departmentsByTypeAndName: { type: "Department", filter: { or: [{ siteID: { eq: traineeData.data?.siteID } }, { siteID: { eq: twin_site_id } }], visibleToTrainees: { ne: false } } }
            };
        }
        const onError = onSuccess;
        var preferencesInput = []
        for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
            console.log([traineeData.data?.siteID, wildcard_site_id])
            preferencesInput.push(<ReferenceInput label={ordinal_suffix_of(priority + 1) + " Preference"} source={"departmentID." + priority} reference="departments" filter={filter} perPage={1000}>
                <SelectInput optionText="name" label={ordinal_suffix_of(priority + 1) + " Preference"} />
            </ReferenceInput >)
        }
        var deadlineDate = new Date(currentRotation?.deadline).toLocaleDateString('en-GB', {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        });
        if (currentRotation?.deadline == null) {
            deadlineDate = "TBC"
        }
        return (
            <Create {...props} mutationOptions={{ onError, onSuccess, meta: { many: true } }} actions={<BackButton />}>
                <SimpleForm validate={validatePreference} toolbar={<SubmitPreferenceToolbar />}>
                    <Typography display="block">
                        Please add your preferences for your next seat rotation.
                    </Typography>
                    <br />
                    <Typography display="block">
                        You will need to complete all {process.env.REACT_APP_NUM_OF_PREFRENCES} preferences and you must choose {process.env.REACT_APP_NUM_OF_PREFRENCES} different departments.
                    </Typography>
                    <br />
                    <Typography display="block">
                        If you would like to check which seats you put as preferences in an earlier rotation, please check your homepage.
                    </Typography>
                    <br />
                    <Typography display="block">
                        Once you save your preferences you will not be able to change these, so please make sure that you are happy with your seat preferences.
                    </Typography>
                    <br />
                    <Typography>
                        Please submit your preferences by {deadlineDate}
                    </Typography>
                    {isSmall ?
                        (
                            <ManyPreferenceCreateMobile preferencesInput={preferencesInput} />
                        )
                    :
                         (
                            <ManyPreferenceCreateWeb preferencesInput={preferencesInput}/>)
                    }
                    
                </SimpleForm>
            </Create>
        )
    }
}
const StyledRichTextInput = styled(RichTextInput)({
    '& .ProseMirror': {
        minHeight: "200px",
        minWidth: "400px"
    }
});

const StyledRichTextInputMobile = styled(RichTextInput)({
    '& .ProseMirror': {
        width: "85vw"
    }
});

export const SubmitPreferenceToolbar = (props) => {
    const [open, setOpen] = useState(false);
    const { submit } = props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form


    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = (response) => {
        notify(`Failed`);
    };
    const onSuccess = (response) => {
        notify(`Failed`);
    };
    const handleClick = (event) => { event.preventDefault(); setOpen(true); }
    const handleDialogClose = () => setOpen(false);

    return (
        <Toolbar>
            <SaveButton label="Submit" onClick={handleClick} {...props} />
            <Dialog
                open={open}
                onClose={handleDialogClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you submit your preferences for this rotation you will not be able to change them. If you need to modify a preference please contact your grad team.
                    </DialogContentText>
                </DialogContent >
                <DialogActions>
                    <Button label="Close" variant="outlined" onClick={handleDialogClose} startIcon={<Close />}>Close</Button>
                    <SaveButton label="Submit" {...props} onClick={submit} type="button" />
                </DialogActions>
            </Dialog >
        </Toolbar>
    );
}

export const PreferenceCreate = (props) => (
    <Create {...props} actions={<BackButton />}>
        <SimpleForm>
            <ReferenceInput label="Department" source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={1000}>
                <SelectInput label="Department" optionText={record => <DepartmentPlusSiteField record={record} />} />
            </ReferenceInput>
            <NumberInput source="priority" defaultValue="1" />
            <ReferenceInput source="traineeID" reference="trainees" perPage={1000} />
            <ReferenceInput source="rotationID" reference="rotations" />
        </SimpleForm>
    </Create>
);

export const PreferenceEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();


    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/trainees');
        refresh();
    };
    return (
        <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }} actions={<BackButton />}>
            <SimpleForm>
                <ReferenceInput source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={1000}>
                    <SelectInput source="name" label="Department" optionText={record => <DepartmentPlusSiteField record={record} />} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>)
};

export const PreferenceShow = (props) => {
    return (
        <Show {...props} actions={<BackButton />}>
            <SimpleShowLayout>
                <ReferenceField source="rotationID" reference="rotations" label="Rotation" />
                <ReferenceField source="departmentID" reference="departments" label="Department" />
                <NumberField source="priority" />
            </SimpleShowLayout>
        </Show>)
};




export const PreferenceList = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <ReferenceField source="departmentID" reference="departments" label="Department">
                    <TextField source="name" />
                </ReferenceField>
                {/* <ReferenceField source="traineeID" reference="trainees" label="Trainee" /> */}
                {/* <TextField source="traineeID" /> */}
                <TraineeNameField/>
                <ReferenceField source="rotationID" reference="rotations"/>

                <NumberField source="priority" />
            </Datagrid>
        </List>
    );
};